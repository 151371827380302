<template>
    <div id="reorder_fields_container" class="container-fluid">
        <div v-if="!isReadOnly()" class="row">
            <div class="pull-left">
                <h4 class="margin-top-20">Reorder Fields</h4>
            </div>
            <div class="pull-right">
                <a href="Javascript:void(0);" @click="selectAllFields">
                    Select All
                </a>&nbsp;|&nbsp;
                <a href="Javascript:void(0);" @click="clearAllFields">
                    Clear All
                </a>&nbsp;&nbsp;
                <a href="Javascript:void(0);"
                    class="btn btn-primary btn-action btn-sm"
                    data-action="edit"
                    data-crud="1"
                    data-path="tenant-company/content"
                    data-route="module-clone-manage-fields-from-other"
                    data-modal-title="Copy Field Settings From Module"
                    :data-idval="id"
                    data-id="id"
                    data-script="module_clone_manage_fields"
                    data-modal="1"
                    :data-option="doption"
                    data-toggle="tooltip"
                    data-container="body"
                    title="Copy Field Settings From Module">
                    Copy from ...
                </a>
            </div>
        </div>

        <div class="row">
            <form id="adminform" method="POST" :action="'tenant-company/content/module-columns-dynamic/' + id">
                <input name="_method" type="hidden" value="PUT" />
                <input name="doption" type="hidden" :value="doption" />
                <input v-model="reorderJson" name="reorder" type="hidden" />
                <input v-model="deletedFieldsJson" name="deleted_fields" type="hidden" />
                <input v-model="mappingFieldsJson" name="mapping_fields" type="hidden" />

                <draggable
                    :list="listData"
                    group="group"
                    class="drag_area"
                    ghost-class="ghost"
                    handle=".group_dragger"
                    @start="drag=true"
                    @end="onEnd"
                    :move="onCheckGroupItemMove"
                    emptyInsertThreshold="5"
                    :disabled="isReadOnly()"
                    item-key="key"
                    >
                    <template v-slot:item="{ element }">
                        <div :key="element.group">
                            <template v-if="element.group === 1">
                                <div class="margin-top-20 margin-bottom-10" :key="element.group + '_message'">Group 1 fields can only be reordered using drag and drop. Fields cannot be moved into or out of this group.</div>
                            </template>
                            <template v-if="getCurrentGroupNum(element.group) === 2">
                                <div class="margin-top-20 margin-bottom-10" :key="element.group + '_message'">For all groupings below, both the order and groupings of fields can be modified using drag and drop.</div>
                            </template>

                            <li class="o_group list-group-item list-group-header">
                                <span v-if="element.group > 1 && !isReadOnly()" class="glyphicon glyphicon-move dragger group_dragger"></span>
                                <span v-else-if="!isReadOnly()" class="glyphicon glyphicon-move dragger item_dragger" style="visibility: hidden;"></span>
                                &nbsp;&nbsp;&nbsp;Group {{ getCurrentGroupNum(element.group) }}
                            </li>

                            <draggable
                                :list="element.fields"
                                group="list_item"
                                class="drag_area"
                                ghost-class="ghost"
                                handle=".item_dragger"
                                @start="drag=true"
                                @end="onEnd"
                                :move="onCheckListItemMove"
                                :key="element.group + '_list_group_item'"
                                emptyInsertThreshold="5"
                                draggable = ".draggable"
                                :disabled="isReadOnly()"
                                item-key="key"
                                >
                                <template v-slot:item="fieldInfo">
                                    <li v-if="isValidField(fieldInfo.element)" :key="fieldInfo.element" :data-key="fieldInfo.element" :class="(isFieldDraggable(element.group, fieldInfo.element) ? 'draggable' : '') + ' o_item list-group-item field-info'">
                                        <span v-if="isFieldDraggable(element.group, fieldInfo.element)" class="glyphicon glyphicon-move dragger item_dragger"></span>
                                        <span v-else class="glyphicon glyphicon-move dragger item_dragger" style="visibility: hidden;"></span>
                                        &nbsp;&nbsp;&nbsp;
                                        <template v-if="!isFieldRequired(fieldInfo.element)">
                                            <input type="checkbox" v-model="enabledFields" name="input_fields[]" :value="fieldInfo.element" :disabled="isFieldRequired(fieldInfo.element) || isReadOnly()">
                                        </template>
                                        <template v-else>
                                            <input type="checkbox" :checked="true" :disabled="true" style="visibility:hidden">
                                            <input type="hidden" name="input_fields[]" :value="fieldInfo.element" :checked="true" />
                                        </template>

                                        <!-- Field name English -->
                                        <span class="field-name">
                                            {{ fieldMap[fieldInfo.element].value.EN }}
                                            <span v-if="data.language !== 'EN' && fieldMap[fieldInfo.element].custom" class="label label-default" style="margin-left:0px">EN</span>
                                        </span>

                                        <!-- Field name for custom fields in languages other than English: show the language label (or indicate blank) as well. -->
                                        <span class="field-name" v-if="fieldMap[fieldInfo.element].custom && (data.language !=='EN')">
                                            <span v-if="fieldMap[fieldInfo.element].value[data.language]">
                                                {{ fieldMap[fieldInfo.element].value[data.language] }}
                                            </span>
                                            <span v-else>
                                                <span style="color:red">&lt;blank&gt;</span>
                                            </span>
                                            <span class="label label-default">{{ data.language }}</span>
                                        </span>

                                        <div class="field-mapping-div" v-if="showMapping">
                                            <span>Mapping:</span>
                                            <select v-model="dataFieldMapping1[fieldInfo.element]" :disabled="isReadOnly()" style="margin-left: 3px;">
                                                <option v-for="(label, key) in dataFieldMappingFields" :value="key" :key="key" :selected="isSelected(dataFieldMapping1, fieldInfo.element, key)">{{ label }}</option>
                                            </select>
                                            <select v-model="dataFieldMapping2[fieldInfo.element]" :disabled="isReadOnly()" style="margin-left: 3px;">
                                                <option v-for="(label, key) in dataFieldMappingFields" :value="key" :key="key" :selected="isSelected(dataFieldMapping2, fieldInfo.element, key)">{{ label }}</option>
                                            </select>
                                        </div>

                                        <div v-if="showDeleteBtn(fieldInfo.element)" class="pull-right">
                                            <input type="checkbox" v-model="enabledFields" :value="fieldInfo.element" :disabled="isFieldRequired(fieldInfo.element) || isReadOnly()" style="visibility: hidden;">
                                            <a href="Javascript:void(0);" class="btn-danger btn-sm" @click="removeCustomField(fieldInfo.element)">
                                                <i class="fas fa-trash" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </li>
                                </template>
                            </draggable>
                        </div>
                    </template>
                </draggable>

                <div v-if="groupingFieldShow">
                    <br>
                    <label class="control-label form-label" for="grouping_field" >Grouping Field</label>
                    <select v-model="groupingField" name="grouping_field" class="form-control" placeholder="Grouping Field (optional)" :disabled="isReadOnly()">
                        <template v-for="groupingField in groupingFields" :key="groupingField.key">
                            <option :value="groupingField.key">{{ groupingField.value.EN }}</option>
                        </template>
                    </select>
                </div>

            </form>
        </div>

        <div class="row">
            <add-field
                v-if="!isReadOnly()"
                :id="id"
                :doption="doption"
                :language="language"
                :companyLanguages="companyLanguages"
                v-on:field-added="onFieldAdded"
                >
            </add-field>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import _ from 'lodash';
    import addField from './AddField';
    import { inject } from 'vue';

    export default {
        components: { draggable, addField },
        props: ['allData'],
        setup() {
            return { data: inject('data') };
        },
        computed: {
            deletedFieldsJson() {
                return JSON.stringify(this.deletedFields);
            },
            reorderJson() {
                const data = {};
                this.listData.forEach((item, i) => {
                    // The groups need to follow the sorted order
                    const group = i + 1;
                    data[group] = item.fields;
                });

                return JSON.stringify(data);
            },
            mappingFieldsJson() {
                // Copy the object
                var baseMapping = { ...this.dataFieldMapping1 };

                for (const [to, from] of Object.entries(this.dataFieldMapping2)) {
                    if (from) {
                        if (to in baseMapping) {
                            let baseFrom = baseMapping[to];

                            if (baseFrom) {
                                baseMapping[to] = [
                                    baseFrom,
                                    from
                                ]
                            } else {
                                baseMapping[to] = from;
                            }
                        } else {
                            baseMapping[to] = from;
                        }
                    }
                }

                return JSON.stringify(baseMapping);
            },
        },
        data() {
            const data = this.data;
            const readOnly = data.dreadonly === '1' ? true : false;
            const groupNums = data.order_columns_groups;
            const orderColumns = data.order_columns;
            const fieldMap = data.field_map;
            const ignoreKeys = data.ignore_columns;
            const groupingFieldShow = data.grouping_field_show;
            const groupingField = data.grouping_field;
            const groupingFields = data.grouping_fields;
            const dataFieldMappingFields = data.data_field_mapping_fields;
            const dataFieldMapping = data.data_field_mapping ? data.data_field_mapping[data.language] : null;
            const dataFieldMapping1 = {};
            const dataFieldMapping2 = {};
            const showMapping = dataFieldMappingFields && Object.keys(dataFieldMappingFields).length > 0;

            if (groupingFields) {
                groupingFields.unshift({
                    'key': '',
                    'value': {
                        'EN': "- None -"
                    }
                });
            }

            // Split out the dataFieldMapping into two separate lists. We only support
            // two selections currently, and although clunky the following code works
            if (dataFieldMapping) {
                for (const [to, from] of Object.entries(dataFieldMapping)) {
                    if (typeof from === 'string') {
                        dataFieldMapping1[to] = from;
                    } else if (Array.isArray(from)) {
                        if (from.length >= 1) {
                            dataFieldMapping1[to] = from[0];
                        }

                        if (from.length >= 2) {
                            dataFieldMapping2[to] = from[1];
                        }
                    }
                }
            }

            const enabledFields = this.getEnabledFields(groupNums, orderColumns, ignoreKeys);
            const listData = this.getListData(groupNums, orderColumns, ignoreKeys);

            const { _id, doption, language, company_languages } = this.data;

            return {
                id: _id,
                doption,
                language,
                companyLanguages: company_languages,
                listData,
                enabledFields,
                deletedFields: [],
                groupNums,
                orderColumns,
                fieldMap,
                ignoreKeys,
                readOnly,
                groupingFieldShow,
                groupingField,
                groupingFields,
                dataFieldMappingFields,
                showMapping,
                dataFieldMapping1,
                dataFieldMapping2
            };
        },
        methods: {
            getListData(groupNums, orderColumns, ignoreKeys) {
                var listData = [];
                groupNums.forEach((groupNum) => {
                    const groupFields = orderColumns[groupNum] || [];
                    let fields = [];

                    groupFields.forEach((fieldKey) => {
                        if (this.isValidField(fieldKey)) {
                            fields.push(fieldKey);
                        }
                    });

                    listData.push({
                        group: groupNum,
                        fields
                    });
                });

                return listData;
            },
            getEnabledFields(groupNums, orderColumns, ignoreKeys) {
                const enabledFields = [];

                groupNums.forEach((groupNum) => {
                    const groupFields = orderColumns[groupNum] || [];
                    let fields = [];

                    groupFields.forEach((fieldKey) => {
                        if (this.isValidField(fieldKey)) {
                            if (this.isFieldRequired(fieldKey) || ignoreKeys.indexOf(fieldKey) === -1) {
                                // Not ignored.
                                enabledFields.push(fieldKey);
                            }
                        }
                    });
                });

                return enabledFields;
            },
            isSelected(jsonObject, to, from) {
                if (to in jsonObject) {
                    if (jsonObject[to] === from) {
                        return true;
                    }
                }

                return false;
            },
            getCurrentGroupNum(groupId) {
                return _.findIndex(this.listData, (item) => {
                    return item.group === groupId;
                }) + 1;
            },
            isReadOnly() {
                return this.readOnly
            },
            isFieldRequired(fieldKey) {
                const fieldInfo = this.data.field_map[fieldKey];
                const required = !fieldInfo.hasOwnProperty('optional') || !fieldInfo.optional;

                return required;
            },
            isFieldDraggable(groupNum, fieldKey) {
                if (this.readOnly) {
                    return false;
                }

                return groupNum > 1 || (groupNum === 1 && ['facebook', 'twitter', 'youtube', 'instagram', 'linkedin'].indexOf(fieldKey) !== -1);
            },
            isValidField(fieldKey) {
                const fieldInfo = this.data.field_map[fieldKey];
                return fieldInfo && !fieldInfo.onlysetup;
            },
            showDeleteBtn(fieldKey) {
                if (this.readOnly) {
                    return false;
                }

                const fieldMap = this.fieldMap;
                return fieldMap[fieldKey] && fieldMap[fieldKey].custom;
            },
            // Actions
            selectAllFields() {
                let enabledFields = [];
                this.listData.forEach((item) => {
                    enabledFields.push(...item.fields);
                });

                this.enabledFields = enabledFields;
            },
            clearAllFields() {
                const self = this;
                let enabledFields = [];
                this.listData.forEach((item) => {
                    item.fields.forEach((fieldKey) => {
                        // Add back fields that are required
                        if (self.isFieldRequired(fieldKey)) {
                            enabledFields.push(fieldKey);
                        }
                    });
                });

                this.enabledFields = enabledFields;
            },
            removeCustomField(fieldKey) {
                if (confirm('Are you sure you want to remove this field? This will also remove the data associated with this field from the existing records.')) {
                    const index = this.enabledFields.indexOf(fieldKey);
                    if (index !== -1) { // Avoid making the last element disabled
                        this.enabledFields.splice(index, 1);
                    }

                    this.listData.forEach((item, i) => {
                        const groupFields = item.fields;
                        groupFields.forEach((dataFieldKey, j) => {
                            if (dataFieldKey === fieldKey) {
                                this.listData[i].fields.splice(j, 1);
                            }
                        }, this);
                    });

                    this.deletedFields.push(fieldKey);
                }
            },
            // Events
            onCheckGroupItemMove(evt, originalEvent) {
                const fromContext = evt.draggedContext;
                const toContext = evt.relatedContext;

                // Cannot drag group to index 0 or 1 (Group 1, 2)
                if (fromContext.futureIndex <= 1) {
                    return false;
                }
            },
            onCheckListItemMove(evt, originalEvent) {
                const fromFieldKey = evt.draggedContext.element;
                const toFieldKey = evt.relatedContext.element;
                let fromItem = null;
                let toItem = null;

                // This should be the old list data
                this.listData.forEach((item) => {
                    item.fields.forEach((fieldKey) => {
                        if (fieldKey === fromFieldKey) {
                            fromItem = item;
                        }

                        if (fieldKey === toFieldKey) {
                            toItem = item;
                        }
                    });
                });

                // Cannot drag a list item out of group 1
                if (fromItem.group === 1 && (!toItem || toItem.group !== 1)) {
                    return false;
                }

                // Cannot drag a list item to group 1
                if (fromItem.group !== 1 && (toItem && toItem.group === 1)) {
                    return false;
                }
            },
            onEnd(evt) {
            },
            onFieldAdded(response) {
                // Add to map
                const field = response.field;
                this.fieldMap[field.key] = response.field;

                // Add to list data
                const index = this.listData.length - 1;
                this.listData[index].fields.push(field.key);

                // Add to enabled fields
                this.enabledFields.push(field.key);
            }
        }
    }
</script>
<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag_area {

}

.dragger {
  cursor: pointer;
}

.field-info {
    display: flex;
    align-items: center;
}

input[type=check-box].field-info {
    margin: 0;
}

.field-name {
    min-width: 200px;
    margin-left: 8px;
}

</style>
